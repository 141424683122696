export default {
	"__PRICE__": "PRICE",
	"__BUY__": "BUY NOW",
	"__PURCHASE__": "PURCHASE",
	"__PURCHASED__": "PURCHASED",
	"__KEY__":"KEY",
	"__CLOSE__": "CLOSE",
	"__PROCESSING__": "PROCESSING",
	"__COMPLETED__": "COMPLETED",
	"__OK__":"OK",
	"__CREATE__":"CREATE",
	"__SEARCH__":"SEARCH",
	"__SEARCH_BY_STATUS__":"SEARCH BY STATUS",
	"__SEARCH_BY_PACKAGE__":"SEARCH BY PACKAGE",
	"__SEARCH_BY_HWID__":"SEARCH BY MULTI HWID / SINGLE HWID",
	"__SEARCH_BY_CREATED__":"SEARCH BY Created Time",
	"__ALL_KEY_MAN__":"ALL KEY MANAGER",
	"__KEY_MAN__":"KEY MANAGER",
	"__NO_HWID__":"NO HWID",
	"__KEYS__":"KEYS",
	"__TRANSACTION__":"TRANSACTION",
	"__STATISTICS__":"STATISTICS",
	"__TOTALKEYS__":"TOTAL KEYS",
	"__UNUSEDKEYS__":"UNUSED KEYS",
	"__USEDKEYS__":"USED KEYS",
	"__EXPIREDKEYS__":"EXPIRED KEYS",
	"__BALANCE__":"BALANCE",
	"__TEST_KEYS__":"TEST KEYS",
	"__SOCIALS__":"SOCIALS",
	"__NEWS__":"NEWS",
	"__SHOP__":"SHOP",
	"__CHANGEPASS__":"CHANGE PASSWORD",
	"__LOGOUT__":"LOG OUT",
	"__LOGOUTALL__":"LOGOUT ALL Devices",
	"__KEYNAME__":"NAME OF KEYS",
	"__KEYFORMAT__":"KEY FORMAT",
	"__NUMKEYS__":"NUMBER OF KEYS",
	"__PACKAGEONKEY__":"Show Package On KEY",
	"__SHOPFORMAT__":"Shoppy Format",
	"__CREATETESTKEY__":"TEST KEY ?",
	"__PACKAGECREATE__":"PACKAGE",
	"__TOTALCREATE__":"TOTAL :",
	"__DATAKEY__":"KEY",
	"__DATADETAILS__":"DETAILS",
	"__DATAHWID__":"HWID",
	"__DATAPACKAGE__":"PACKAGE",
	"__DATAPLAYEDTIME__":"PLAYED TIME",
	"__DATAMODIFY__":"MODIFY",
	"__DATAHI__":"HI",
	"__KEYRENEW__":"RENEW",
	"__KEYREFUND__":"REFUND",
	"__KEYLOCK__":"LOCK",
	"__KEYUNLOCK__":"UNLOCK",
	"__KEYDELETE__":"DELETE",
	"__KEYREGEN__":"RE-GEN",
	"__RESETALLHWID__":"RESET ALL HWID",
	"__LOCKALLKEYS__":"LOCK ALL KEYS",
	"__UNLOCKALLKEYS__":"UNLOCK ALL KEYS",
	"__EXPORTUNUSED__":"EXPORT UNUSED KEYS",
	"__CANCEL__":"CANCEL",
	"__PCINFO__":"PCINFO",
	"__CREATEDATE__":"CREATE DATE",
	"__EXPIREDATE__":"EXPIRE DATE",
	"__FIRSTLOGIN__":"FIRST LOGIN",
	"__LASTLOGIN__":"LAST LOGIN",
	"__KEYUNSED__":"KEY UNUSED",
	"__LISTHWID__":"LIST HWID",
	"__1HWID__":"1 HWID",
	"__2HWID__":"2 HWID",
	"__ALL__":"ALL",
	"__SEARCHSTATUS_USED__":"USED",
	"__SEARCHSTATUS_UNUSED__":"UNUSED",
	"__SEARCHSTATUS_EXPIRED__":"EXPIRED",
	"__SEARCHSTATUS_REGENERATED__":"REGENERATED",
	"__SEARCHPACKAGE_2HOUR__":"2 HOUR",
	"__SEARCHPACKAGE_1DAY__":"1 DAY",
	"__SEARCHPACKAGE_7DAY__":"7 DAY",
	"__SEARCHPACKAGE_15DAY__":"15 DAY",
	"__SEARCHPACKAGE_30DAY__":"30 DAY",
	"__SEARCHHWID_SINGLE__":"SINGLE",
	"__SEARCHHWID_MULTI__":"MULTI",
	"__SEARCHKEYS_1DAY__":"1 DAY",
	"__SEARCHKEYS_1WEEK__":"7 WEEK",
	"__FUND_ADD__":"ADD FUND TO MY ACCOUNT",
	"__FUND_AMOUNT__":"AMOUNT",
	"__FUND_TOPUP__":"TOPUP",
	"__DATA_ROWS__":"ROWS PER PAGE",
	"__MOKKANEWS__":"NEWS",
	"__ERROR_INVALID_GOOGLE_AUTH__":"INVALID GOOGLE AUTHENTICATION CODE",
	"__ERROR_INVALID_AUTH__":"INVALID USER OR PASSWORD",
	"__ERROR_2FA__":"PLEASE ENABLE 2FA BEFORE LOGIN",
	"__ERROR_UPDATE__":"ERROR WHEN UPDATE. TRY AGAIN !!!",
	"__ERROR_NET__":"NOT ENOUGH TEST KEY !!!",
	"__ERROR_NEB__":"INSUFFICIENT BALANCE !!!",
	"__ERROR_KNF__":"KEY NOT FOUND !!!",
	"__ERROR_KDELETED__":"KEY WAS DELETED !",
	"__ERROR_KCHANGE__":"KEY NOT USED OR KEY EXPIRED OR WAS CHANGED 1 TIME !!!",
	"__ERROR_NKE__":"NO KEY EFFECT !!!",
	"__ERROR_KNA__":"KEY NOT ALLOWED FOR THIS ACTION !!!",
	"__ERROR_ANA__":"YOUR ACCOUNT NOT ALLOWED FOR THIS ACTION !!!",
	"__ERROR_OPWD__":"Your old password not match !!!",
	"__ERROR_DATA__":"The data request invalid !!!",
	"__SUCCESS_KRENEWED__":"KEY RENEWED !!!",
	"__SUCCESS_UPDATED__":"DATA UPDATED !!!",
	"__SUCCESS_KUPDATED__":"Key(s) UPDATED !",
	"__SUCCESS_KREFUNDED__":"KEY REFUNDED !!!",
	"__SUCCESS_PURCHASED__":"KEY(s) PURCHASED !!!",
}
