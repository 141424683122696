export function fetchData(url,data,method)
{
    let DefaultRequest = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('Token'),
        },
    };
    if(typeof(data) !== "undefined")
    {
        DefaultRequest.method = "POST";
        DefaultRequest.body = JSON.stringify(data);
    }
    if(typeof(method) !== "undefined"){
        DefaultRequest.method = method;
    }
    return fetch(`${url}`,DefaultRequest).then((resp)=>{
        if (resp.status === 401) {
            return Promise.reject(401);
        }
        if(resp.status !== 200){
            return Promise.reject(resp.status);
        }
        return resp.json();
    },(error)=>{
        return Promise.reject(error);
    });
}