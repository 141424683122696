<template>
   <nav>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showNews"
      >
      <v-card>
            <v-toolbar
              color="green"
              dark
            >
            <v-toolbar-title><v-icon left>fas fa-volume-up</v-icon>{{nn('__NEWS__',lang)}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showNews=false"><v-icon>fas fa-times</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-12" v-html="SellerStats.news"></div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="showNews=false"
              >{{ nn('__CANCEL__',lang) }}</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
      <v-overlay :value="Overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
       <v-app-bar color="blue" dark app >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"
           :right="lang === 'ar'"
          />
           <v-toolbar-title class="text-uppercase">
               <span class="font-weight-light">{{ !SellerStats['username'] ? 'SELLER':SellerStats.username.toUpperCase() }}</span>
               <span>PANEL</span>
           </v-toolbar-title>
          <template v-if="status.loggedIn">
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <template v-if="SellerStats.enableTopup">
              <v-menu offset-y :close-on-content-click="false" :nudge-width="250">
                <template v-slot:activator="{ on, attrs }">
                <v-btn dark text v-bind="attrs" v-on="on">
                    <v-icon left>
                    fas fa-dollar-sign
                    </v-icon>
                    {{nn('__BALANCE__',lang)}}: {{SellerStats.balance}}
                </v-btn>
                </template>
                <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{nn('__FUND_ADD__',lang)}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
                 <v-list>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>fas fa-dollar-sign</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>
                        <v-select v-model="newBalance" :items="listTopup" :label="nn('__FUND_AMOUNT__',lang)" item-text="text" item-value="value"></v-select>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn text color="green" dark @click="doTopup" :loading="Overlay">{{nn('__FUND_TOPUP__',lang)}} <v-icon right>mdi-plus</v-icon></v-btn>
                      </v-list-item-title>
                    </v-list-item>
                 </v-list>
              </v-menu>
            </template>
            <template v-else>
                <v-btn dark text>
                    <v-icon left>
                    fas fa-dollar-sign
                    </v-icon>
                    {{nn('__BALANCE__',lang)}}: {{SellerStats.balance}}
                </v-btn>
            </template>
            <button :data-shoppy-product="shoppyOrder" id="shoppyPayBtn" @click="CheckClick"></button>        
            <v-btn color="orange lighten-2" text v-if="SellerStats.enableTest">
                <v-icon left>
                  fas fa-key
                </v-icon>
                {{nn('__TEST_KEYS__',lang)}}: {{SellerStats.testkeys}}
            </v-btn>
            <v-menu offset-y :close-on-content-click="false" :nudge-width="400" dark>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="cyan lighten-3"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  text
                >
                  {{nn('__SOCIALS__',lang)}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                      <v-icon>fab fa-telegram</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                      <v-text-field v-model="socials['telegram']['link']" label="Telegram" class="ma-1" />
                      <v-checkbox v-model="socials['telegram']['auto']" label="Auto" class="ma-1" small />
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                      <v-icon>fab fa-discord</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                      <v-text-field v-model="socials['discord']['link']" label="Discord" />
                      <v-checkbox v-model="socials['discord']['auto']" label="Auto" class="ma-1" small />
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                      <v-icon>fas fa-shopping-basket</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                      <v-text-field v-model="socials['shoppy']['link']" label="Shoppy" />
                      <v-checkbox v-model="socials['shoppy']['auto']" label="Auto" class="ma-1" small />
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                      <v-icon>fab fa-facebook</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                      <v-text-field v-model="socials['facebook']['link']" label="Facebook" class="ma-1" />
                      <v-checkbox v-model="socials['facebook']['auto']" label="Auto" class="ma-1" small />
                  </v-list-item-title>
                </v-list-item>
								<v-list-item>
									<v-list-item-action>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
											<path fill="#fff" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
										</svg>
									</v-list-item-action>
									<v-list-item-title>
										<v-text-field v-model="socials['tiktok']['link']" label="TikTok"></v-text-field>
                    <v-checkbox v-model="socials['tiktok']['auto']" label="Auto" class="ma-1" small />
									</v-list-item-title>
								</v-list-item>
                <v-list-item>
                  <v-list-item-action>
                      
                  </v-list-item-action>
                  <v-list-item-title>
                      <v-btn text color="green" dark @click="doUpdateSocial" :loading="Overlay">Update <v-icon right>fas fa-cloud-upload-alt</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y :close-on-content-click="false" :nudge-width="400" dark>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="orange"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  text
                >
                  {{nn('__NEWS__',lang)}}
                </v-btn>
              </template>
                  <v-card>
                    <v-card-title>
                      <v-row>
                        <v-col cols=12>
                            <v-textarea
                              v-model="seller_news"
                              outlined
                              label="News"
                            />
                        </v-col>
                        <v-col cols=6>
                          <v-btn text color="green" dark @click="doUpdateNews" :loading="Overlay">Update <v-icon right>fas fa-cloud-upload-alt</v-icon></v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col cols=6>
                          <v-btn text color="red" dark @click="doUpdateNews" :loading="Overlay">Clear</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
            </v-menu>
          </template>
           <v-spacer></v-spacer>
            <v-menu offset-y v-if="status.loggedIn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                    <span>{{ nn('__DATAHI__',lang) }}, {{SellerStats.username}}</span>
                    <v-icon right>mdi-account-reactivate</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <Dialog 
                      :text="nn('__CHANGEPASS__',lang)"
                      :title="nn('__CHANGEPASS__',lang)"
                      color="green"
                      icon="mdi-form-textbox-password"
                    >
                      <v-card flat>
                        <v-card-title>{{nn('__CHANGEPASS__',lang)}}</v-card-title>
                        <v-card-text>
                          <v-form v-model="PasswordValid">
                            <v-row>
                              <v-col
                                cols=6
                              >
                                <v-text-field
                                  v-model="ChangePassword.O"
                                  label="Old Password"
                                  type="password"
                                  :rules="[required('Old password')]"
                                />
                              </v-col>
                              <v-col
                                cols=6
                              >
                              </v-col>
                              <v-col
                                cols=6
                              >
                                <v-text-field
                                  v-model="ChangePassword.N"
                                  label="New Password"
                                  type="password"
                                  :rules="[required('New password')]"
                                />
                              </v-col>
                              <v-col
                                cols=6
                              >
                                <v-text-field
                                  label="Re New Password"
                                  type="password"
                                  :rules="[rePassword(ChangePassword.N)]"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                              >
                                <v-alert
                                    dense
                                    :type="alert.type"
                                    timeout="5000"
                                    v-if="alert.message"
                                >
                                    {{ nn(alert.message,lang) }}
                                </v-alert>
                              </v-col>
                              <v-col 
                                cols=6
                              >
                                <v-btn
                                  color="primary"
                                  dark
                                  :disabled="!PasswordValid"
                                  @click="doChangePassword"
                                >
                                  {{nn('__CHANGEPASS__',lang)}}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <Loading :status="Overlay" />
                      </v-card>
                    </Dialog>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn @click="Logout()" color="orange" small>{{nn('__LOGOUT__',lang)}} <v-icon right>exit_to_app</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn @click="LogoutAll()" color="red" small>{{nn('__LOGOUTALL__',lang)}} <v-icon right>exit_to_app</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y v-if="status.loggedIn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                    <flag :iso="languages[lang].flag" />
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item,index) in Object.entries(languages)"
                  :key=index
                >
                  <v-list-item-title>
                    <v-btn text @click="doLanguage(item[0])">
                      <flag :iso="item[1].flag" />
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
       </v-app-bar>
      <v-navigation-drawer  v-model="drawer" dark app class="blue darken-3" v-if="status.loggedIn"
      :right="lang==='ar'"
      >
          <v-list flat>
              <v-list-item v-for="link in links"  :key="link.text" router :to="link.route" :active-class="lang === 'ar' ? 'border_right':'border'">
                  <v-list-item-action>
                     <v-icon >{{link.icon}}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title >{{link.text}}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item>
                  <v-list-item-action>
                     <v-icon>mdi-newspaper</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title><v-btn text @click="DoShowNews">{{nn('__MOKKANEWS__',lang)}}</v-btn></v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item>
                  <v-list-item-content >
                      <v-divider />
                  </v-list-item-content>
              </v-list-item>
              <v-list-item>
                  <v-list-item-action>
                     <v-icon>mdi-chart-gantt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title>{{nn('__STATISTICS__',lang)}}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-1"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          {{nn('__TOTALKEYS__',lang)}}: {{SellerStats.keys[0]}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-2"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          {{nn('__UNUSEDKEYS__',lang)}}: {{SellerStats.keys[1]}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-3"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          {{nn('__USEDKEYS__',lang)}}: {{SellerStats.keys[2]}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-4"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          {{nn('__EXPIREDKEYS__',lang)}}: {{SellerStats.keys[3]}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
										<v-list-item>
											<v-list-item-title>
												<v-btn
													color="orange lighten-6"
													class="ma-2 white--text"
													small
													text
												>
													Online: {{SellerStats.online}}
												</v-btn>
											</v-list-item-title>
										</v-list-item>
                  </v-list>
                </v-list-item-content>
              </v-list-item>
          </v-list>
      </v-navigation-drawer>
   </nav>
</template>
<script>
import { fetchData } from '../helpers';
import languageHelper from '../helpers/language';
import { mapState,mapActions } from 'vuex'
import Loading from '../components/Loading.vue'
import Dialog from '../components/Dialog.vue'
import validations from '../plugins/validations'
const nn = languageHelper.nn
export default {
  components: {
    Loading,
    Dialog
  },
   data: () => ({
      auto_stats: undefined,
      languages:{
        en:{lang:"en",flag:"us"},
        ar:{lang:"ar",flag:"eg"},
      },
      seller_news: "",
      socials:{
        telegram: {},
        discord: {},
        facebook: {},
        shoppy: {},
        tiktok: {},
      },
      Overlay: false,
      drawer: true,
      PasswordValid: false,
      ChangePassword: {
        O: '',
        N: '',
      },
      shoppyOrder: "",
      paymentEnable: false,
      showNews:false,
      newBalance: 0,
    }),

  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('auth', ['status']),
    ...mapState('SellerStats', ['SellerStats']),
    ...mapState({
        alert: state => state.alert
    }),
    links(){
      return [
          {icon: 'mdi-account-key', text:nn('__KEYS__',this.lang), route: '/'},
          {icon: 'mdi-order-alphabetical-ascending', text:nn('__TRANSACTION__',this.lang), route: '/transaction'},
          {icon: 'mdi-cart', text:nn('__SHOP__',this.lang), route: '/shop'},
          {icon: 'mdi-key-chain', text:nn('__PURCHASED__',this.lang), route: '/purchased'},
					{icon: 'mdi-monitor', text:'STREAM', route: '/stream'},
          {icon: 'mdi-account-cowboy-hat', text:'LOGS', route: '/logs'},
      ]
    },
  // newBalance(){
  //     if(this.SellerStats.topup_package[0])
  //     {
  //       return this.SellerStats.topup_package[0];
  //     }
  //     return 0;
  // },
    listTopup(){
      if(this.SellerStats.topup_package.length > 0){
        let newData = [];
        const topup_package =this.SellerStats.topup_package;
        topup_package.forEach(element => {
          newData.push({value:element,text:"$"+element});
        });
        return newData;
      }else{
        return [{value:null,text:"NO PACKAGE"}];
      }
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    ...mapActions('alert', ['success', 'error']),
    ...validations,
    ...languageHelper,
    Logout () {
      this.logout();
    },
    CheckClick(){
      console.log('Shoppy.GG Paying');
    },
    DoShowNews(){
      this.showNews = true;
    },
    doLanguage(lang,force=true){
      if(lang === 'ar'){
        this.$vuetify.rtl = true
      }else{
        this.$vuetify.rtl = false
      }
      this.$store.dispatch("lang/change",lang);
      if(force){
        fetchData(`${this.$API}/api/language`,{lang:lang},'PUT')
      }
    },
    doUpdateNews: function(){
      if(this.status.loggedIn !== true){
        return;
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/news`,{news:this.seller_news},'PUT').then((json)=>{
        let msg = (json.msg) ? json.msg:"SERVER ERROR !!!";
        if(json.success === true){
          this.success(msg);
          let API = this.$API;
          this.$store.dispatch("SellerStats/updateData",{API});
        }else{
          this.error(msg);
        }
      },(err)=>{
        if(err === 401){
          this.Logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doUpdateSocial: function(){
      if(this.status.loggedIn !== true){
        return;
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/social`,{social:this.socials},'PUT').then((json)=>{
        let msg = (json.msg) ? json.msg:"SERVER ERROR !!!";
        if(json.success === true){
          this.success(msg);
          let API = this.$API;
          this.$store.dispatch("SellerStats/updateData",{API});
        }else{
          this.error(msg);
        }
      },(err)=>{
        if(err === 401){
          this.Logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doTopup: function(){
      if(this.SellerStats.enableTopup !== true) return;
      this.Overlay = true;
      fetchData(`${this.$API}/api/topup`,{package:this.newBalance}).then((json)=>{
        let msg = (json.msg) ? json.msg:"SERVER ERROR !!!";
        if(json.success === true && json.data != null)
        {
          this.shoppyOrder = json.data;
          this.paymentEnable = true;
          setTimeout(()=>{
            try{
              document.getElementById("shoppyPayBtn").click();
            }catch(err){
              console.log(err);
            }
            this.Overlay = false;
          },1000);
        }else{
          this.error(msg);
        }
      },(err)=>{
        if(err === 401){
          this.Logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      });
    },
    doChangePassword(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/password`,{...this.ChangePassword,},'POST').then((json)=>{
        let msg = (json.msg) ? json.msg:"SERVER ERROR !!!";
        if(json.success === true){
          this.success(msg);
          this.Logout();
        }else{
          this.error(msg);
        }
      },(err)=>{
        if(err === 401){
          this.Logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    LogoutAll(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/logout`).finally(()=>{
        this.Overlay = false;
        this.Logout();
      });
    }
  },
  watch:{
    status: function(newData){
      if(newData.loggedIn === true){
        let API = this.$API;
        this.$store.dispatch("SellerStats/updateData",{API});
      }
    },
    SellerStats: function(newData){
      if(newData.language) this.doLanguage(newData.language,false)
      if(newData.social){
        this.socials = newData.social;
        this.seller_news = newData.seller_news;
      }
      if(newData.topup_package && newData.topup_package.length > 0)
      {
        this.newBalance  = newData.topup_package[0];
      }
    },
  },
  mounted(){
    if(this.status.loggedIn === true){
      let API = this.$API;
      this.$store.dispatch("SellerStats/updateData",{API});
      this.auto_stats = setInterval(()=>{
        this.$store.dispatch("SellerStats/updateData",{API});
      },10000);
    }
    let shoppyGGscript = document.createElement('script')
    shoppyGGscript.setAttribute('src', 'https://shoppy.gg/api/embed.js');
    document.head.appendChild(shoppyGGscript);
  },
  beforeDestroy() {
    clearInterval(this.auto_stats);
    this.auto_stats = undefined;
  }
}
</script>
<style scoped>
.border {
  border-left: 4px solid #2196f3;
}
.border_ar {
  border-right: 4px solid #2196f3;
}
</style>
