<template>
    <v-dialog
            v-model="boxGameLogs"
            max-width="800px"
    >
        <v-card>
            <v-card-title>GAME LOGS</v-card-title>
            <v-card-text>
                <v-textarea
                        v-model="game_logs"
                        dark
                        readonly
                />
                <v-overlay :value="loading">
                    <v-progress-circular
                            indeterminate
                            size="64"
                    />
                </v-overlay>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="blue"
                        dark
                        @click="boxGameLogs = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {fetchData} from "../helpers";
import Notice from "../helpers/notice";
export default {
    name: "GameLogs",
    data(){
        return {
            loading: false,
            boxGameLogs: false,
            game_logs: '',
        }
    },
    methods: {
        FetchGameLogs(key_id, type){
            this.loading = true;
            this.boxGameLogs = true;
            this.game_logs = '';
            fetchData(`${this.$API}/api/${type}/game_logs`,{ key_id }).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success === true){
                    this.game_logs = json.data['game_logs'];
                }else{
                    Notice.error({message});
                    this.boxGameLogs = false;
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    Notice.error({message: "SERVER ERROR !!!"});
                }
            }).finally(()=>{
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>