export default {
	"__PRICE__": "السعر",
	"__BUY__": "شراء الأن",
	"__PURCHASE__": "شراء",
	"__PURCHASED__": "تم الشراء",
	"__KEY__":"المفتاح",
	"__CLOSE__": "أغلاق",
	"__PROCESSING__": "تتم المعالجه",
	"__COMPLETED__": "تم الانتهاء",
	"__OK__":"حسنا",
	"__CREATE__":"أنشاء",
	"__SEARCH__":"بحث",
	"__SEARCH_BY_STATUS__":"بحث بالحاله",
	"__SEARCH_BY_PACKAGE__":"بحث بالمده",
	"__SEARCH_BY_HWID__":"مفتاح ثنائى / مفتاح فردى",
	"__SEARCH_BY_CREATED__":"بحب بوقت الانشاء",
	"__ALL_KEY_MAN__":"ادارة جميع المفاتيح",
	"__KEY_MAN__":"ادارة المفاتيح",
	"__NO_HWID__":"لا يوجد هاردوير",
	"__KEYS__":"المفاتيح",
	"__TRANSACTION__":"المعاملات",
	"__STATISTICS__":"الاحصائيات",
	"__TOTALKEYS__":"جميع المفاتيح",
	"__UNUSEDKEYS__":"المفاتيح الغير مستخدمه",
	"__USEDKEYS__":"المفاتيح المستخدمه",
	"__EXPIREDKEYS__":"المفاتيح المنتهية",
	"__BALANCE__":"الرصيد",
	"__TEST_KEYS__":"مفاتيح التجربه",
	"__SOCIALS__":"التواصل",
	"__NEWS__":"الأخبار",
	"__SHOP__":"المتجر",
	"__CHANGEPASS__":"تغير الرقم السرى",
	"__LOGOUT__":"تسجيل خروج",
	"__LOGOUTALL__":"تسجيل خروج جميع الاجهزة",
	"__KEYNAME__":"اسم المفتاح",
	"__KEYFORMAT__":"شكل المفتاح",
	"__NUMKEYS__":"عدد المغاتيح",
	"__PACKAGEONKEY__":"اظهار المده على المفتاح",
	"__SHOPFORMAT__":"صيغة الشوبى",
	"__CREATETESTKEY__":"مفتاح تجريبى ?",
	"__PACKAGECREATE__":"المده",
	"__TOTALCREATE__":"الأجمالى :",
	"__DATAKEY__":"المفتاح",
	"__DATADETAILS__":"المعلومات",
	"__DATAHWID__":"الهاردوير",
	"__DATAPACKAGE__":"المده",
	"__DATAPLAYEDTIME__":"لعب الوقت",
	"__DATAMODIFY__":"تعديل",
	"__DATAHI__":"أهلا",
	"__KEYRENEW__":"تجديد",
	"__KEYREFUND__":"استعاده",
	"__KEYLOCK__":"قفل",
	"__KEYUNLOCK__":"فتح القفل",
	"__KEYDELETE__":"مسح",
	"__KEYREGEN__":"اعاده تعين",
	"__RESETALLHWID__":"ترسيت جميع المفاتيح",
	"__LOCKALLKEYS__":"قفل جميع المفاتيح",
	"__UNLOCKALLKEYS__":"فتح قفل جميع المفاتيح",
	"__EXPORTUNUSED__":"استخراج المفاتيح الغير مستخدمه",
	"__CANCEL__":"إلغاء",
	"__PCINFO__":"معلومات الحساب",
	"__CREATEDATE__":"تاريخ الأنشاء",
	"__EXPIREDATE__":"تاريخ الأنتهاء",
	"__FIRSTLOGIN__":"اول تسجيل",
	"__LASTLOGIN__":"اخر تسجيل",
	"__KEYUNSED__":"مفتاح غير مستخدم",
	"__LISTHWID__":"جميع الاجهزة",
	"__1HWID__":"جهاز واحد",
	"__2HWID__":"جهازين",
	"__ALL__":"الجميع",
	"__SEARCHSTATUS_USED__":"مستخدم",
	"__SEARCHSTATUS_UNUSED__":"غير مستخدم",
	"__SEARCHSTATUS_EXPIRED__":"المنتهى",
	"__SEARCHSTATUS_REGENERATED__":"المعاد بناءه",
	"__SEARCHPACKAGE_2HOUR__":"مفتاح تجريبى",
	"__SEARCHPACKAGE_1DAY__":"يوم",
	"__SEARCHPACKAGE_7DAY__":"اسبوع",
	"__SEARCHPACKAGE_15DAY__":"اسبوعين",
	"__SEARCHPACKAGE_30DAY__":"شهر",
	"__SEARCHHWID_SINGLE__":"واحد",
	"__SEARCHHWID_MULTI__":"اثنان",
	"__SEARCHKEYS_1DAY__":"يوم",
	"__SEARCHKEYS_1WEEK__":"اسبوع",
	"__FUND_ADD__":"اضافة رصيد لحسابى",
	"__FUND_AMOUNT__":"القيمة",
	"__FUND_TOPUP__":"اضافه",
	"__DATA_ROWS__":"عدد الأسطر",
	"__MOKKANEWS__":"الأخبار",
	"__ERROR_INVALID_GOOGLE_AUTH__":"خطأ كود جوجل",
	"__ERROR_INVALID_AUTH__":"اسم مستخدم او باسورد حاطئ",
	"__ERROR_2FA__":"من فضلك قم بتفعيل تأكيد الدخول",
	"__ERROR_UPDATE__":"مشكله اثناء التحديث",
	"__ERROR_NET__":"ليس لديك مفاتيح تجريبية",
	"__ERROR_NEB__":"لا يوجد رصيد",
	"__ERROR_KNF__":"المفتاح غير موجود",
	"__ERROR_KDELETED__":"تم مسح المفتاح",
	"__ERROR_KCHANGE__":"المفتاح غير مستخدم",
	"__ERROR_NKE__":"لا يوجد خيارات",
	"__ERROR_KNA__":"المفتاح غير مسموح لهذه العملية",
	"__ERROR_ANA__":"حسابك غير مسموح بهذه العملية",
	"__ERROR_OPWD__":"باسورد القديم غير متوافق",
	"__ERROR_DATA__":"مشكلة بالبيانات",
	"__SUCCESS_KRENEWED__":"تم التجديد",
	"__SUCCESS_UPDATED__":"تم تجديد الاعدادات",
	"__SUCCESS_KUPDATED__":"تم تجديد المفاتيح",
	"__SUCCESS_KREFUNDED__":"تم استعاده المفتاح",
	"__SUCCESS_PURCHASED__":"تم شراء المفتاح",
}
