<template>
    <v-dialog
            v-model="box"
            max-width="800px"
    >
        <v-card>
            <v-card-title>BLACKLIST KEY: {{item['key_login']}}</v-card-title>
            <v-card-text>
                <v-textarea
                        v-model="data.reason"
                        dark
                        label="Reason"
                />
                <v-overlay :value="loading">
                    <v-progress-circular
                            indeterminate
                            size="64"
                    />
                </v-overlay>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    dark
                    color="green"
                    @click="doBlacklist"
                    :loading="loading"
                    :disabled="loading"
                >
                    Do Blacklist
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                        color="blue"
                        dark
                        @click="box = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {fetchData} from "../helpers";
import Notice from "../helpers/notice";
export default {
    name: "GameLogs",
    props: {
        callback: {
            type: Function,
            default: ()=>{}
        }
    },
    data(){
        return {
            loading: false,
            box: false,
            item: {},
            data: {
                key_id: '',
                reason: '',
                key_type: 'keys',
            }
        }
    },
    methods: {
        Show(item, type){
          this.box = true;
          this.item = item;
          this.data = {
              key_id: item.key_id,
              reason: '',
              key_type: type,
          }
        },
        doBlacklist(){
            this.loading = true;
            fetchData(`${this.$API}/api/blacklist/add`,this.data).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success === true){
                    Notice.success({message});
                    this.box = false;
                    this.callback();
                }else{
                    Notice.error({message});
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    Notice.error({message: "SERVER ERROR !!!"});
                }
            }).finally(()=>{
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>