import Swal from 'sweetalert2'
export default {
    success: ({title, message})=>Swal.fire(
        title||'OK',
        message,
        'success'
    ),
    error: ({title, message})=>Swal.fire(
        title||'Oops...',
        message,
        'error'
    ),
}